import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import { withStyles } from '@material-ui/core/styles';

const ETableCell = withStyles(theme => ({
    root: {
        backgroundColor: '#0f0f0f',
        color: '#fafafa',
        fontFamily: 'Montserrat'
    }
    }))(TableCell);

const ESelect = withStyles(theme => ({
    root: {
        color: '#fafafa',
        fontFamily: 'Montserrat'
    }
    }))(Select);
    
const EInputLabel = withStyles(theme => ({
    root: {
        color: '#fafafa',
        fontFamily: 'Montserrat'
    }
    }))(InputLabel);

class YourStory extends Component {
    state = {
        claimStatus: "All",
    }

    render() {
        let data = [
            {status: "-", tokenId: "388000000", tokenNumber: "0"},
            {status: "Unclaimed", tokenId: "388000001", tokenNumber: "1"},
            {status: "Unclaimed", tokenId: "388000002", tokenNumber: "2"},
            {status: "Unclaimed", tokenId: "388000003", tokenNumber: "3"},
            {status: "Unclaimed", tokenId: "388000004", tokenNumber: "4"},
            {status: "Unclaimed", tokenId: "388000005", tokenNumber: "5"},
            {status: "Unclaimed", tokenId: "388000006", tokenNumber: "6"},
            {status: "Unclaimed", tokenId: "388000007", tokenNumber: "7"},
            {status: "Unclaimed", tokenId: "388000008", tokenNumber: "8"},
            {status: "Unclaimed", tokenId: "388000009", tokenNumber: "9"},
            {status: "Unclaimed", tokenId: "388000010", tokenNumber: "10"},
            {status: "Unclaimed", tokenId: "388000011", tokenNumber: "11"},
            {status: "Unclaimed", tokenId: "388000012", tokenNumber: "12"},
            {status: "Unclaimed", tokenId: "388000013", tokenNumber: "13"},
            {status: "Unclaimed", tokenId: "388000014", tokenNumber: "14"},
            {status: "Unclaimed", tokenId: "388000015", tokenNumber: "15"},
            {status: "Unclaimed", tokenId: "388000016", tokenNumber: "16"},
            {status: "Unclaimed", tokenId: "388000017", tokenNumber: "17"},
            {status: "Unclaimed", tokenId: "388000018", tokenNumber: "18"},
            {status: "Unclaimed", tokenId: "388000019", tokenNumber: "19"},
            {status: "Unclaimed", tokenId: "388000020", tokenNumber: "20"},
            {status: "Unclaimed", tokenId: "388000021", tokenNumber: "21"},
            {status: "Unclaimed", tokenId: "388000022", tokenNumber: "22"},
            {status: "Unclaimed", tokenId: "388000023", tokenNumber: "23"},
            {status: "Unclaimed", tokenId: "388000024", tokenNumber: "24"},
            {status: "Unclaimed", tokenId: "388000025", tokenNumber: "25"},
            {status: "Unclaimed", tokenId: "388000026", tokenNumber: "26"},
            {status: "Unclaimed", tokenId: "388000027", tokenNumber: "27"},
            {status: "Unclaimed", tokenId: "388000028", tokenNumber: "28"},
            {status: "Unclaimed", tokenId: "388000029", tokenNumber: "29"},
            {status: "Unclaimed", tokenId: "388000030", tokenNumber: "30"},
            {status: "Unclaimed", tokenId: "388000031", tokenNumber: "31"},
            {status: "Unclaimed", tokenId: "388000032", tokenNumber: "32"},
            {status: "Unclaimed", tokenId: "388000033", tokenNumber: "33"},
            {status: "Unclaimed", tokenId: "388000034", tokenNumber: "34"},
            {status: "Unclaimed", tokenId: "388000035", tokenNumber: "35"},
            {status: "Unclaimed", tokenId: "388000036", tokenNumber: "36"},
            {status: "Unclaimed", tokenId: "388000037", tokenNumber: "37"},
            {status: "Unclaimed", tokenId: "388000038", tokenNumber: "38"},
            {status: "Unclaimed", tokenId: "388000039", tokenNumber: "39"},
            {status: "Unclaimed", tokenId: "388000040", tokenNumber: "40"},
            {status: "Unclaimed", tokenId: "388000041", tokenNumber: "41"},
            {status: "Unclaimed", tokenId: "388000042", tokenNumber: "42"},
            {status: "Unclaimed", tokenId: "388000043", tokenNumber: "43"},
            {status: "Unclaimed", tokenId: "388000044", tokenNumber: "44"},
            {status: "Unclaimed", tokenId: "388000045", tokenNumber: "45"},
            {status: "Unclaimed", tokenId: "388000046", tokenNumber: "46"},
            {status: "Unclaimed", tokenId: "388000047", tokenNumber: "47"},
            {status: "Unclaimed", tokenId: "388000048", tokenNumber: "48"},
            {status: "Unclaimed", tokenId: "388000049", tokenNumber: "49"},
            {status: "Unclaimed", tokenId: "388000050", tokenNumber: "50"},
            {status: "Claimed", tokenId: "388000051", tokenNumber: "51"},
            {status: "Unclaimed", tokenId: "388000052", tokenNumber: "52"},
            {status: "Unclaimed", tokenId: "388000053", tokenNumber: "53"},
            {status: "Unclaimed", tokenId: "388000054", tokenNumber: "54"},
            {status: "Unclaimed", tokenId: "388000055", tokenNumber: "55"},
            {status: "Unclaimed", tokenId: "388000056", tokenNumber: "56"},
            {status: "Unclaimed", tokenId: "388000057", tokenNumber: "57"},
            {status: "Unclaimed", tokenId: "388000058", tokenNumber: "58"},
            {status: "Unclaimed", tokenId: "388000059", tokenNumber: "59"},
            {status: "Unclaimed", tokenId: "388000060", tokenNumber: "60"},
            {status: "Unclaimed", tokenId: "388000061", tokenNumber: "61"},
            {status: "Unclaimed", tokenId: "388000062", tokenNumber: "62"},
            {status: "Unclaimed", tokenId: "388000063", tokenNumber: "63"},
            {status: "Unclaimed", tokenId: "388000064", tokenNumber: "64"},
            {status: "Unclaimed", tokenId: "388000065", tokenNumber: "65"},
            {status: "Claimed", tokenId: "388000066", tokenNumber: "66"},
            {status: "Unclaimed", tokenId: "388000067", tokenNumber: "67"},
            {status: "Unclaimed", tokenId: "388000068", tokenNumber: "68"},
            {status: "Unclaimed", tokenId: "388000069", tokenNumber: "69"},
            {status: "Unclaimed", tokenId: "388000070", tokenNumber: "70"},
            {status: "Unclaimed", tokenId: "388000071", tokenNumber: "71"},
            {status: "Unclaimed", tokenId: "388000072", tokenNumber: "72"},
            {status: "Unclaimed", tokenId: "388000073", tokenNumber: "73"},
            {status: "Unclaimed", tokenId: "388000074", tokenNumber: "74"},
            {status: "Unclaimed", tokenId: "388000075", tokenNumber: "75"},
            {status: "Claimed", tokenId: "388000076", tokenNumber: "76"},
            {status: "Unclaimed", tokenId: "388000077", tokenNumber: "77"},
            {status: "Unclaimed", tokenId: "388000078", tokenNumber: "78"},
            {status: "Unclaimed", tokenId: "388000079", tokenNumber: "79"},
            {status: "Unclaimed", tokenId: "388000080", tokenNumber: "80"},
            {status: "Unclaimed", tokenId: "388000081", tokenNumber: "81"},
            {status: "Unclaimed", tokenId: "388000082", tokenNumber: "82"},
            {status: "Unclaimed", tokenId: "388000083", tokenNumber: "83"},
            {status: "Claimed", tokenId: "388000084", tokenNumber: "84"},
            {status: "Claimed", tokenId: "388000085", tokenNumber: "85"},
            {status: "Unclaimed", tokenId: "388000086", tokenNumber: "86"},
            {status: "Unclaimed", tokenId: "388000087", tokenNumber: "87"},
            {status: "Unclaimed", tokenId: "388000088", tokenNumber: "88"},
            {status: "Unclaimed", tokenId: "388000089", tokenNumber: "89"},
            {status: "Unclaimed", tokenId: "388000090", tokenNumber: "90"},
            {status: "Unclaimed", tokenId: "388000091", tokenNumber: "91"},
            {status: "Unclaimed", tokenId: "388000092", tokenNumber: "92"},
            {status: "Unclaimed", tokenId: "388000093", tokenNumber: "93"},
            {status: "Unclaimed", tokenId: "388000094", tokenNumber: "94"},
            {status: "Unclaimed", tokenId: "388000095", tokenNumber: "95"},
            {status: "Unclaimed", tokenId: "388000096", tokenNumber: "96"},
            {status: "Claimed", tokenId: "388000097", tokenNumber: "97"},
            {status: "Unclaimed", tokenId: "388000098", tokenNumber: "98"},
            {status: "Unclaimed", tokenId: "388000099", tokenNumber: "99"},
            {status: "Unclaimed", tokenId: "388000100", tokenNumber: "100"},
            {status: "Unclaimed", tokenId: "388000101", tokenNumber: "101"},
        ]
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    {window.innerWidth > 1120 ?
                        <div>
                        <div className="collectionDiv">
                                <p className="collectionTitle">
                                    Your Story
                                </p>
                                <a href="https://www.artblocks.io/collections/presents/projects/0x99a9b7c1116f9ceeb1652de04d5969cce509b069/388?section=all%20items&category=collections&vertical=presents&contract_id=0x99a9b7c1116f9ceeb1652de04d5969cce509b069&project_id=388&orderByKey=list_eth_price&orderDirection=asc_nulls_last" target="_blank" rel="noopener noreferrer">
                                    Sold out - secondary on artblocks
                                </a>
                                <p>
                                    <i>Your Story</i> is a book like no other.<br/><br/>

                                    It's the story you always wanted to read,<br/>
                                    the story you always wanted to create.<br/>
                                    Any story you want.<br/>
                                    <i>Your Story</i> is your imagination projected within the pages,<br/>
                                    to tell a story today,<br/>
                                    and another tomorrow.<br/>
                                </p>
                                <hr/>
                                <h3>The idea:</h3><br/>
                                <p>
                                    I was playing with a dot on a canvas, when I recognized the first symbol.<br/>
                                    In the end, writing is not different from drawing.<br/>
                                    So I generated another. And another.<br/>
                                    That's how I realized that I had discovered a new language. As a writer, I knew what I had to do:<br/>
                                    let this language tell the stories it wants.<br/><br/>

                                    Characters in modern alphabets are symbols that, over thousands of years, we aligned on in order to comunicate in written form.<br/>
                                    Most people can tell which character represents what in the alphabet they're most familiar with.<br/>
                                    There is no room for imagination anymore in the characters themselves. It's all up to the combination of them.<br/>
                                    So we form words, sentences, chapters, books.<br/><br/>

                                    But imagine now a language that no one understands.<br/>
                                    Every interpretation of it is valid: there is no wrong answer.<br/>
                                    Anyone reading it is perceiving a different story, creating a different story.<br/>
                                    It's <i>your</i> own choice.<br/>
                                    You can decide to change the story at every reading. Or keep a piece of it consistent. Collaborate with others to create a new story.<br/><br/>

                                    <i>Your Story</i> is giving back full imagination to <i>you</i>.<br/> 
                                </p>
                                <hr/>
                                <h3>How it works:</h3><br/>
                                <p>
                                    I wanted <i>Your Story</i> to look like a book, and all pieces of the algorithm follow this aesthetic direction.<br/>
                                    The aspect ratio is set to √2:1, as most of the paper sizes available worldwide (ISO 216, ISO 217 and ISO 269).<br/>
                                    Two set tokens (first and last) are the cover and the blurb.<br/>
                                    The remaining tokens form the interior, using token ID to determine whether the page is left or right and the respective margins.<br/>
                                    The hash is used to determine the aesthetics of the page: the character style, the type of page (chapter header, illustration, normal text), the presence of notes, and highlights.<br/>
                                    All character styles have clear differences between them, and occasionally they are all mixed together.<br/>
                                    <i>Your Story</i> is not meant to be a single alphabet, but rather an exploration of the imagination. Different character styles may evoke different thoughts.<br/>
                                </p>
                                <hr/>
                            </div>
                            <div className="coupleCollections">
                                <div className="collectionLeft">
                                    <img alt='' src='/images/yourstory/0.png' className="pieceImgGen" />
                                    <img alt='' src='/images/yourstory/2.png' className="pieceImgGen" />
                                    <img alt='' src='/images/yourstory/4.png' className="pieceImgGen" />
                                    <img alt='' src='/images/yourstory/6.png' className="pieceImgGen" />
                                    <img alt='' src='/images/yourstory/8.png' className="pieceImgGen" />
                                    <img alt='' src='/images/yourstory/10.png' className="pieceImgGen" />
                                </div>
                                <div className="collectionRight">
                                    <img alt='' src='/images/yourstory/1.png' className="pieceImgGen" />
                                    <img alt='' src='/images/yourstory/3.png' className="pieceImgGen" />
                                    <img alt='' src='/images/yourstory/5.png' className="pieceImgGen" />
                                    <img alt='' src='/images/yourstory/7.png' className="pieceImgGen" />
                                    <img alt='' src='/images/yourstory/9.png' className="pieceImgGen" />
                                    <img alt='' src='/images/yourstory/11.png' className="pieceImgGen" />
                                </div>
                            </div>
                            <div style={{margin:50}}>
                                <h3 id="prints">Paperback prints:</h3><br/>
                                <p>
                                    All pages are eligible to claim a paperback <b>ONLY ONCE</b>, you can track the status below to see if a page has already been used to claim or not.<br/>
                                    To claim your paperback, reach out to me on <a href="https://twitter.com/encapsuled_" target="_blank" rel="noopener noreferrer">twitter</a> or via discord in the ArtBlocks server.
                                </p>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                <EInputLabel id="demo-simple-select-label">Claim status</EInputLabel>
                                <ESelect
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.claimStatus}
                                    label="Claim status"
                                    onChange={(event) => this.setState({claimStatus: event.target.value})}
                                    >
                                    <MenuItem value={"Unclaimed"}>Unclaimed</MenuItem>
                                    <MenuItem value={"Claimed"}>Claimed</MenuItem>
                                    <MenuItem value={"All"}>All</MenuItem>
                                </ESelect>
                            </FormControl>
                            <TableContainer component={Paper}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <ETableCell>Page</ETableCell>
                                        <ETableCell>Paperback claim status</ETableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {data.filter(d => this.state.claimStatus === "All" ? true : d.status === this.state.claimStatus).map((row) => (
                                    <TableRow
                                        key={row.tokenId}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <ETableCell component="th" scope="row">
                                            <a href={"https://www.artblocks.io/collections/presents/projects/0x99a9b7c1116f9ceeb1652de04d5969cce509b069/388/tokens/" + row.tokenId} target="_blank" rel="noopener noreferrer" >{row.tokenNumber}</a>
                                        </ETableCell>
                                        <ETableCell>{row.status}</ETableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                            </TableContainer>
                            </div>
                        </div>
                    :
                        <div className="collectionLeft">
                            <p className="collectionTitle">
                                    Your Story
                                </p>
                                <a href="https://www.artblocks.io/collections/presents/projects/0x99a9b7c1116f9ceeb1652de04d5969cce509b069/388?section=all%20items&category=collections&vertical=presents&contract_id=0x99a9b7c1116f9ceeb1652de04d5969cce509b069&project_id=388&orderByKey=list_eth_price&orderDirection=asc_nulls_last" target="_blank" rel="noopener noreferrer">
                                    Sold out - secondary on artblocks
                                </a>
                                <p>
                                    <i>Your Story</i> is a book like no other.<br/><br/>

                                    It's the story you always wanted to read,<br/>
                                    the story you always wanted to create.<br/>
                                    Any story you want.<br/>
                                    <i>Your Story</i> is your imagination projected within the pages,<br/>
                                    to tell a story today,<br/>
                                    and another tomorrow.<br/>
                                </p>
                                <hr/>
                                <h3>The idea:</h3><br/>
                                <p>
                                    I was playing with a dot on a canvas, when I recognized the first symbol.<br/>
                                    In the end, writing is not different from drawing.<br/>
                                    So I generated another. And another.<br/>
                                    That's how I realized that I had discovered a new language. As a writer, I knew what I had to do:<br/>
                                    let this language tell the stories it wants.<br/><br/>

                                    Characters in modern alphabets are symbols that, over thousands of years, we aligned on in order to comunicate in written form.<br/>
                                    Most people can tell which character represents what in the alphabet they're most familiar with.<br/>
                                    There is no room for imagination anymore in the characters themselves. It's all up to the combination of them.<br/>
                                    So we form words, sentences, chapters, books.<br/><br/>

                                    But imagine now a language that no one understands.<br/>
                                    Every interpretation of it is valid: there is no wrong answer.<br/>
                                    Anyone reading it is perceiving a different story, creating a different story.<br/>
                                    It's <i>your</i> own choice.<br/>
                                    You can decide to change the story at every reading. Or keep a piece of it consistent. Collaborate with others to create a new story.<br/><br/>

                                    <i>Your Story</i> is giving back full imagination to <i>you</i>.<br/> 
                                </p>
                                <hr/>
                                <h3>How it works:</h3><br/>
                                <p>
                                    I wanted <i>Your Story</i> to look like a book, and all pieces of the algorithm follow this aesthetic direction.<br/>
                                    The aspect ratio is set to √2:1, as most of the paper sizes available worldwide (ISO 216, ISO 217 and ISO 269).<br/>
                                    Two set tokens (first and last) are the cover and the blurb.<br/>
                                    The remaining tokens form the interior, using token ID to determine whether the page is left or right and the respective margins.<br/>
                                    The hash is used to determine the aesthetics of the page: the character style, the type of page (chapter header, illustration, normal text), the presence of notes, and highlights.<br/>
                                    All character styles have clear differences between them, and occasionally they are all mixed together.<br/>
                                    <i>Your Story</i> is not meant to be a single alphabet, but rather an exploration of the imagination. Different character styles may evoke different thoughts.<br/>
                                </p>
                                <hr/>
                            <img alt='' src='/images/yourstory/0.png' className="pieceImgGen" />
                            <img alt='' src='/images/yourstory/1.png' className="pieceImgGen" />
                            <img alt='' src='/images/yourstory/2.png' className="pieceImgGen" />
                            <img alt='' src='/images/yourstory/3.png' className="pieceImgGen" />
                            <img alt='' src='/images/yourstory/4.png' className="pieceImgGen" />
                            <img alt='' src='/images/yourstory/5.png' className="pieceImgGen" />
                            <img alt='' src='/images/yourstory/6.png' className="pieceImgGen" />
                            <img alt='' src='/images/yourstory/7.png' className="pieceImgGen" />
                            <img alt='' src='/images/yourstory/8.png' className="pieceImgGen" />
                            <img alt='' src='/images/yourstory/9.png' className="pieceImgGen" />
                            <img alt='' src='/images/yourstory/10.png' className="pieceImgGen" />
                            <img alt='' src='/images/yourstory/11.png' className="pieceImgGen" />
                            <div style={{margin:50}}>
                                <h3 id="prints">Paperback prints:</h3><br/>
                                <p>
                                    All pages are eligible to claim a paperback <b>ONLY ONCE</b>, you can track the status below to see if a page has already been used to claim or not.<br/>
                                    To claim your paperback, reach out to me on <a href="https://twitter.com/encapsuled_" target="_blank" rel="noopener noreferrer">twitter</a> or via discord in the ArtBlocks server.
                                </p>
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                <EInputLabel id="demo-simple-select-label">Claim status</EInputLabel>
                                <ESelect
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.claimStatus}
                                    label="Claim status"
                                    onChange={(event) => this.setState({claimStatus: event.target.value})}
                                >
                                    <MenuItem value={"Unclaimed"}>Unclaimed</MenuItem>
                                    <MenuItem value={"Claimed"}>Claimed</MenuItem>
                                    <MenuItem value={"All"}>All</MenuItem>
                                </ESelect>
                            </FormControl>
                            <TableContainer component={Paper}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <ETableCell>Page</ETableCell>
                                        <ETableCell>Paperback claim status</ETableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {data.filter(d => this.state.claimStatus === "All" ? true : d.status === this.state.claimStatus).map((row) => (
                                    <TableRow
                                        key={row.tokenId}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <ETableCell component="th" scope="row">
                                            <a href={"https://www.artblocks.io/collections/presents/projects/0x99a9b7c1116f9ceeb1652de04d5969cce509b069/388/tokens/" + row.tokenId} target="_blank" rel="noopener noreferrer" >{row.tokenNumber}</a>
                                        </ETableCell>
                                        <ETableCell>{row.status}</ETableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                            </TableContainer>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default YourStory;