import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"
import { Link } from "react-router-dom";

import { ReactP5Wrapper } from "react-p5-wrapper";
import sketch from '../components/SketchEndlessDialogue';

export default class EndlessDialogue extends Component {
    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    <div>
                        <div className="collectionDiv">
                            <p className="collectionTitle">
                                Endless dialogue
                            </p>
                            <a href="https://opensea.io/collection/endless-dialogue" target="_blank" rel="noopener noreferrer">
                                Secondary on OpenSea
                            </a>
                            <p>I wish we could talk forever</p>
                            <ReactP5Wrapper sketch={sketch}/>
                            <br/><br/>
                            <p>
                                URL parameters controls (add them to the URL to alter the dialogue):<br/><br/>
                                s - Number of speakers 1-5<br/>
                                s1,...,s5 - Speaker styles 0.01-5<br/>
                                t - Thickness 1-5<br/>
                                o - Talk over chance 0-1<br/>
                                d - Dialogue style 0-1<br/>
                                b - Brightness 0-100<br/>
                                c - Angle continuity 1-100<br/>
                                h - Hollow 0/1<br/>
                                r - Reversed 0/1<br/>
                                a - Aspect ratio correction<br/>
                            </p>
                            <p> 
                                Examples:<br/>
                                <Link reloadDocument to="/endlessdialogue?s=3&t=5">Three speakers, thickness five</Link><br/>
                                <Link reloadDocument to="/endlessdialogue?s=5&o=1">Five speakers, talking over each other often</Link><br/>
                                <Link reloadDocument to="/endlessdialogue?s=1&r=1">One speaker, reversed colors</Link><br/>
                                <Link reloadDocument to="/endlessdialogue?s=4&h=1&t=10&s1=0.01&s2=0.1&s3=0.5&s4=2">Four speakers, hollow, thickness ten, speaker styles defined</Link><br/>
                            </p>
                            <br/><br/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}