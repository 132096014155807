import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"

export default class Evolution extends Component {
    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    <div>
                        <div className="collectionDiv">
                            <p className="collectionTitle">
                                Evolution v0
                            </p>
                            <a href="https://www.mintgolddust.com/artwork/728/" target="_blank" rel="noopener noreferrer">
                                Available on MintGoldDust
                            </a>
                            <br/><br/>
                            <img
                                src='/images/evolution_final.svg'
                                alt='evolution' className="pieceImgWide"
                            />
                            <br/><br/>
                            <p>
                                Evolution v0 is part of my research on language.<br/><br/>
                                Language is something that can only exist with others. It fills the necessity to communicate and organize, so that multiple perspectives can converge in one. 
                                As such, it's alive. It will evolve and adapt to the needs of that particular period and that particular population. 
                                Language in a clique of friends is different than in another, even if they may speak the same overarching language and they live in the same geographical area, even the same town.<br/><br/>
                                Evolution explores how language changes over time, and even if it may seem completely different from one moment to another, it's all part of the same process.
                            </p>
                            <br/><br/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}