import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"

import { ReactP5Wrapper } from "react-p5-wrapper";
import sketch from '../components/SketchNeverendingConversations';

export default class NeverendingConversations extends Component {
    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    <div>
                        <div className="collectionDiv">
                            <p className="collectionTitle">
                                Neverending conversations
                            </p>
                            <ReactP5Wrapper sketch={sketch}/>
                            <br/><br/>
                            <p>
                                Conversations about art are neverending.
                                <br/><br/>
                                Art is subjective and transcends time. The conversations that it inspires can never come to a conclusion, and continuously evolve through time.<br/>
                                How we understand art today is different than in the past, and will be different in the future. We can only read about how it was and try to imagine how it will be but are bound to our present.<br/>
                                <i>Neverending conversations</i>, on the other hand, will continue uninterrupted forever, whether anyone is looking at them or not.
                                <br/><br/>
                                <i>Neverending conversations</i> constantly evolve. Every person has its own position, in some conversations people are closer to each other, in others further apart. The colors of the characters reflect each person's current emotional state and intensity. As they grow more intense or more quiet, transparency and weight changes. Sometimes, conversations can be clearly perceived. Other times, they blend in with their surroundings.
                                <br/><br/>
                                The conversations take place while looking at framed artworks. Compositions of lines and shapes, with an occasional touch of color. If color appears in the composition, it's only one and it's picked from the three primary colors and the three secondary colors according to the RYB color model, part of the Bauhaus curriculum amongst others.
                                <br/><br/>
                                Each conversation is built using a combination of the token hash and the current moment in time. No matter where they are in the world, if two people are looking at the same conversation they will see the same thing.
                                <br/><br/>
                                Press "s" to show/hide conversation shadow.
                                <br/><br/>
                                Refresh the page for a new output.
                                <br/><br/>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}