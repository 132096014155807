import React, { Component } from 'react'
import { Link } from "react-router-dom";
import LeftNav from "../components/LeftNav"

export default class Taccuini extends Component {

    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    {window.innerWidth > 1120 ?
                        <div>
                            <div className="collectionDiv">
                                <p className="collectionTitle">
                                    Taccuini
                                </p>
                                <div className="coupleCollections">
                                    <div className="collectionLeft">
                                    <img alt='' src={'/images/taccuini/firma1.jpg'} className="pieceImgGen" />
                                    </div>
                                    <div className="collectionRight">
                                    <img alt='' src={'/images/taccuini/firma2.jpg'} className="pieceImgGen" />
                                    </div>
                                </div>
                                <p>
                                    Algorithms are not only meant for computers. To study the concept and experiment on the potential of the series I work on, I often draw by hand.
                                    Taccuini is a series of physical notebooks, each fully dedicated to one single algorithm of <Link to="/ricercasemica">Ricerc(a)semica</Link> in a given year.<br/>
                                    <ul>
                                        <li>
                                            <a href="https://www.transient.xyz/nfts/base/0xd1d00954d6deb4c3186cf0dce97a69fe1e249be6/1" target="_blank" rel="noopener noreferrer">Firma '24</a>
                                        </li>
                                        <li>
                                            <a href="https://www.transient.xyz/nfts/base/0xd1d00954d6deb4c3186cf0dce97a69fe1e249be6/2" target="_blank" rel="noopener noreferrer">Danza '24</a>
                                        </li>
                                    </ul>
                                    Schneider Topball 847 on Fabriano Ispira soft cover. All notebooks are authenticated with T.R.A.C.E. from TransientLabs.
                                </p>
                                <p>
                                    For more information or to purchase <a href="mailto:encapsuled.art@gmail.com" target="_blank" rel="noopener noreferrer"> encapsuled.art@gmail.com</a>
                                </p>
                                <br/><br/>
                            </div>
                        </div>
                    :
                        <div className="collectionLeft">
                            <p className="collectionTitle">
                                Taccuini
                            </p>
                            <img alt='' src={'/images/taccuini/firma1.jpg'} className="pieceImgGen" />
                            <img alt='' src={'/images/taccuini/firma2.jpg'} className="pieceImgGen" />
                            <p>
                                Algorithms are not only meant for computers. To study the concept and experiment on the potential of the series I work on, I often draw by hand.
                                Taccuini is a series of physical notebooks, each fully dedicated to one single algorithm of <Link to="/ricercasemica">Ricerc(a)semica</Link> in a given year.<br/>
                                <ul>
                                    <li>
                                        <a href="https://www.transient.xyz/nfts/base/0xd1d00954d6deb4c3186cf0dce97a69fe1e249be6/1" target="_blank" rel="noopener noreferrer">Firma '24</a>
                                    </li>
                                    <li>
                                        <a href="https://www.transient.xyz/nfts/base/0xd1d00954d6deb4c3186cf0dce97a69fe1e249be6/2" target="_blank" rel="noopener noreferrer">Danza '24</a>
                                    </li>
                                </ul>
                                Schneider Topball 847 on Fabriano Ispira soft cover. All notebooks are authenticated with T.R.A.C.E. from TransientLabs.
                            </p>
                            <p>
                                For more information or to purchase <a href="mailto:encapsuled.art@gmail.com" target="_blank" rel="noopener noreferrer"> encapsuled.art@gmail.com</a>
                            </p>
                            <br/><br/>
                        </div>
                    }
                </div>
            </div>
        )
    }
};