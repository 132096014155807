import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"
import { ReactP5Wrapper } from "react-p5-wrapper";
import sketch from '../components/SketchLinea';

export default class Linea extends Component {
    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    <div>
                        <div className="collectionDiv">
                            <p className="collectionTitle">
                                Linea
                            </p>
                            <a href="https://www.artblocks.io/marketplace/collections/linea-by-encapsuled" target="_blank" rel="noopener noreferrer">
                                Available on artblocks studio
                            </a>
                            <br/><br/>
                            <div id='svgWrapper' style={{justifyContent: 'center', display: 'flex'}}>
                                <ReactP5Wrapper sketch={sketch}/>
                            </div>
                            <br/><br/>
                            <p>
                                <i>I never can catch myself at any time without a perception, and never can observe any thing but the perception.</i>
                                And perceptions are not disconnected as we may think. Our minds connect them with rules, proximity, causality: 
                                the points become a line. A line that connects the beginning and the end, that traverses every moment and varies 
                                with the variations of the external and the internal. Maybe influenced more by the context than the content. 
                                It's a story, unique: indivisible and different from every other.
                            </p>
                            <p>
                                Linea is the second chapter of <a href="/ricercasemica">Ricerc(a)semica</a>, a journey into form and meaning of language. 
                                The algorithm explores how lines are formed and react to the passing of time and the accumulation of experiences, 
                                evolving or claiming their own identity by rejecting the changes.
                            </p>
                            <p>
                                Resolution agnostic, svg ready to be plotted, physicals available on request.
                            </p>
                            <br/><br/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}