import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"

import { ReactP5Wrapper } from "react-p5-wrapper";
import sketch from '../components/SketchStorylines';

export default class Storylines extends Component {
    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    <div>
                        <div className="collectionDiv">
                            <p className="collectionTitle">
                                Storylines
                            </p>
                            <br/><br/>
                            <div style={{justifyContent: 'center', display: 'flex'}}>
                                <ReactP5Wrapper sketch={sketch}/>
                            </div>
                            <br/><br/>
                            <p>
                                I write to live multiple lives,<br/>
                                to not be bound by the choices I make.
                            </p>
                            <p>
                                I constantly wonder "What if?"<br/>
                                As I write, I explore possibilities: especially the ones that I could never realize.
                            </p>
                            <p>
                                I set the stage, choose the characters, and watch the play unfold.<br/>
                                I don't know what will happen next, I'm a spectator as much as everyone else.
                            </p>
                            <p>
                                There are always different ideas, different storylines, running in my head.<br/>
                                They never stop, stretching and folding, pausing only to spring back to action.<br/>
                                As the story unfolds, they might get brighter, more intense. Or turn darker.
                            </p>
                            <p>
                                I often park ideas in a corner of my mind, only to find them changed when I return to them.<br/>
                                Storylines act in the same way. Every time you look at them, they will be different.
                            </p>
                            <h3>How does it work</h3>
                            <p>
                                Storylines uses the token hash to determine the general characteristics of each piece, and then a combination of the token hash and the current moment in time to determine where we are in the story.<br/>
                                So every time you look at one, it doesn't restart from the beginning.<br/>
                                The story continues, whether you're observing it or not, just like ideas in our heads.
                            </p>
                            <p>
                                Refresh the page for a different output.
                            </p>
                            <br/><br/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}