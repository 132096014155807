import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"

import { ReactP5Wrapper } from "react-p5-wrapper";
import sketch from '../components/SketchSystemsOfSelf';

export default class SystemsOfSelf extends Component {
    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    <div>
                        <div className="collectionDiv">
                            <p className="collectionTitle">
                                Systems of self
                            </p>
                            <br/><br/>
                            <ReactP5Wrapper sketch={sketch}/>
                            <br/><br/>
                            <p>
                                An apartment building: lives in containers that occasionally connect with each other.
                                Some buildings have group chats, regular meetings, in others people have no contact and have never met.
                                A system composed by multiple entities, similar or different, tied together or not.
                            </p>
                            <p>
                                Our inner "self" is the same: we tend to think of ourself as a single entity, but all our previous experiences shape the way we process new perceptions, and the resulting system decides how to react based on how all its parts interact.
                                Each individual system of self is different: depending on our past, some agree more often than others, some have formed more complex patterns, others are straightforward.
                            </p>
                            <p>
                                And all systems of self are part of the wider system we call society. 
                                As a complex system and part of an even more complex system, our role is to understand the way we operate internally and the way to contribute to the wider system.
                            </p>
                            <br/><br/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}