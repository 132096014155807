import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";

export default function LeftNav() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const StyledMenu = styled((props) => (
    <Menu
      {...props}
    />
  ))(() => ({
    '& .MuiPaper-root': {
      fontFamily: 'Montserrat',
      backgroundColor: "#0f0f0f",
      color: "#fafafa",
      fontSize: "16px",
      padding: "0 10px",
      borderColor: "#fafafa",
      borderRight: "solid",
      borderBottom: "solid",
    },
  }));
  const StyledMenuItem = styled((props) => (
    <MenuItem
      {...props}
    />
  ))(() => ({
    fontFamily: 'Montserrat',
    color: "#fafafa",
    fontSize: "16px",
  }));
  const StyledDivider = styled((props) => (
    <Divider {...props}/>
  ))(() => ({
    borderColor: "#fafafa",
  }));
  return (
    <div className="menuDiv" style={{backgroundColor: "#0f0f0f"}}>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MenuIcon sx={{color: "#fafafa"}}/>
      </Button>
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{}}
      >
        <Link to="/">
            <img
                src="/images/encapsuled_logo_v2.png"
                width="50" height="50" alt="logo" style={{ marginLeft: "auto", marginRight: "auto", marginTop: 10, display: "block" }}
            />
        </Link>
        <p>
            <b>michelangelo (encapsuled)</b>
        </p>
        <StyledMenuItem><Link to="/">Home</Link></StyledMenuItem>
        <StyledDivider/>
        <p>
            Works:
        </p>
        <StyledMenuItem><Link to="/linea">Linea</Link></StyledMenuItem>
        <StyledMenuItem><Link to="/varsemic">var(semic)</Link></StyledMenuItem>
        <StyledMenuItem><Link to="/carattere">Carattere</Link></StyledMenuItem>
        <StyledMenuItem><Link to="/taccuini">Taccuini</Link></StyledMenuItem>
        <StyledMenuItem><Link to="/evolutionv0">Evolution v0</Link></StyledMenuItem>
        <StyledMenuItem><Link to="/endlessdialogue">Endless dialogue</Link></StyledMenuItem>
        <StyledMenuItem><Link to="/loss">Loss</Link></StyledMenuItem>
        <StyledMenuItem><Link to="/linebreak">Linebreak</Link></StyledMenuItem>
        <StyledMenuItem><Link to="/indaco">Indaco</Link></StyledMenuItem>
        <StyledMenuItem><Link to="/chronicles">Chronicles</Link></StyledMenuItem>
        <StyledMenuItem><Link to="/yourstory">Your Story</Link></StyledMenuItem>
        <StyledMenuItem><Link to="/manifesto">Manifesto</Link></StyledMenuItem>
        <StyledMenuItem><Link to="/people">People</Link></StyledMenuItem>
        <StyledMenuItem><Link to="/disegni">Disegni di poesia</Link></StyledMenuItem>
        <StyledMenuItem><Link to="/e28">28</Link></StyledMenuItem>
        <StyledMenuItem><Link to="/capsules">Capsules</Link></StyledMenuItem>
        <StyledMenuItem><Link to="/raw">Raw</Link></StyledMenuItem>
        <StyledMenuItem><Link to="/roads">Roads to nowhere</Link></StyledMenuItem>
        <StyledMenuItem><Link to="/onwriting">On writing</Link></StyledMenuItem>
        <StyledMenuItem><Link to="/ouroboros">Ouroboros</Link></StyledMenuItem>
        <StyledMenuItem><Link to="/engoesback">engoesback</Link></StyledMenuItem>
        <StyledDivider/>
        <p>
            Collaborations:
        </p>
        <StyledMenuItem><Link to="/divergence">Divergence</Link></StyledMenuItem>
        <StyledMenuItem><a href="https://teia.art/objkt/717662" target="_blank" rel="noopener noreferrer">
            encapsuled x coppola
        </a></StyledMenuItem>
      </StyledMenu>
    </div>
  );
}